<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-on="$listeners"
  >
    <path
      d="M28.28 21.187a13.335 13.335 0 0 1-25.005-1.208 13.333 13.333 0 0 1 7.392-16.206"
      stroke="current"
      stroke-width="2.667"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M29.333 16A13.332 13.332 0 0 0 16 2.667V16h13.333z"
      stroke="current"
      stroke-width="2.667"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Strategy",
};
</script>
