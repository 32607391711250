<template>
  <main>
    <section>
      <div class="bg-body-1 rounded-bl-4xl flex pt-16 pb-16">
        <div class="max-w-screen-2xl mx-auto py-0 md:py-16">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div class="lg:hidden mx-auto">
              <img src="/images/Rectangle 67 (1).png" />
            </div>
            <div class="max-w-screen-lg mx-6">
              <h1 class="text-white text-4xl font-bold mb-6">
                Prodáváte nemovitost?
              </h1>
              <h1 class="text-white text-4xl font-bold my-6">
                S námi to zvládnete bez makléře!
              </h1>
              <p class="text-white my-6 leading-8 tracking-wide font-semibold text-lg">
                Prodej nemovitosti je obchodně-administrativní úkol. Vyžaduje
                spoustu času, energie a pro mnohé symbolizuje strach ze ztráty
                peněz. Cílem aplikace MakléřeNechci je prodej nemovitostí
                zjednodušit a učinit ho transparentnější i pro ty, kteří se
                obchodováním s nemovitostmi nezabývají. Aplikace MakléřeNechci
                vás provede celým procesem prodeje domu či bytu a dovede vás až
                ke kýženému cíli – prodat svůj majetek za co nejvyšší cenu,
                rychle a bezpečně. Za prodej nemovitosti přitom nezaplatíte
                žádnou provizi z kupní ceny. Ušetříte tak desetitisíce i
                statisíce korun.
              </p>
              <div class="mb-24 text-center lg:text-left">
                <button class="text-white bg-body-3 hover:bg-opacity-80 h-16 w-64 rounded-md mt-8 font-semibold"
                  @click="$router.push('/registrace')">
                  Registrovat se do aplikace
                </button>
              </div>
            </div>
            <div class="hidden lg:block mx-auto">
              <img src="/images/Rectangle 67 (1).png" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="relative max-w-screen-lg mx-auto mt-16 md:mt-48 mb-8">
        <div class="mx-6 md:mx-16">
          <div class="grid grid-cols-1 md:grid-cols-2 mx-auto gap-8 md:gap-32">
            <div class="col-span-1 z-30 max-w-md mx-auto">
              <img src="/images/Rectangle 68.png" />
              <h2 class="my-6 text-4xl font-bold leading-12 text-body-4">
                Jak prodávají profesionálové?
              </h2>
              <p class="text-body-4 leading-6 font-semibold">
                Profesionál má plán! Shromáždí všechny potřebné informace a
                rozdělí si prodej nemovitosti na menší úkoly. Do obchodu
                nezapojuje emoce a neprodává ani konkrétní podlahovou krytinu
                ani dlaždičky v koupelně… Prodává sen kupujícího! Nemovitost
                nachystá tak, aby oslovil co nejširší skupinu lidí a před
                zveřejněním nabídky připraví všechny potřebné dokumenty –
                smlouvy, výpisy z katastru nemovitostí apod. Jakmile zveřejní
                inzerci, je připraven uzavřít obchod ke spokojenosti své i
                kupujícího.
              </p>
            </div>
            <div class="col-span-1 z-10 max-w-md mx-auto pt-6 md:pt-16">
              <img src="/images/Rectangle 69.png" />
              <h2 class="my-6 text-4xl font-bold leading-12 text-body-4">
                Je prodávají ostatní
              </h2>
              <p class="text-body-4 leading-6 font-semibold">
                Jednorázoví prodejci typicky nepřemýšlejí nad strategií prodeje
                a nerozumí související administrativě. V důsledku toho působí
                neprofesionálně a nevědomky odrazují potenciální kupce.
                Základním problémem je zde neznalost cenotvorby. Neprofesionální
                prodejci často přicházejí s taktikou “nastřelení” ceny a jejího
                následného snižování. Snižování se však snadno vymkne kontrole a
                rychle se dostanou pod reálnou hodnotu nemovitosti. Další chybou
                neprofesionálního prodeje bytu či domu je nedomyšlená inzerce.
                Na ni se chytí realitní makléři a pravděpodobně budou prvními
                zájemci o koupi. Počkají si, až cena klesne, nemovitost koupí a
                po čase prodají výrazně dráž.
              </p>
            </div>
          </div>
          <img src="/images/Rectangle 70.png"
            class="z-20 mx-auto absolute -top-24 left-38 md:h-56 md:w-56 lg:h-64 lg:w-64 hidden md:flex" />
        </div>
      </div>
    </section>
    <section>
      <div class="mt-24 pb-4">
        <h2 class="my-6 text-4xl font-bold leading-12 text-body-4 text-center mx-6">
          Jak vám pomůže aplikace MakléřeNechci
        </h2>
        <p class="leading-6 max-w-xl mx-auto px-6 text-body-4 font-semibold text-justify">
          Díky aplikaci MakléřeNechci svou nemovitost prodáte za dobrou cenu,
          bezpečně a bez začátečnických chyb. Zkrátka budete působit jako
          profesionál, a navíc ušetříte za provizi realitnímu makléři.
        </p>
      </div>

      <div class="mx-auto max-w-screen-lg mt-8">
        <div v-on:click="accordionOpen.guide = !accordionOpen.guide"
          class="border-b flex cursor-pointer select-none relative" :class="accordionOpen.guide
              ? 'border-t border-b border-body-1 bg-body-1 bg-opacity-5 flex pb-2'
              : 'truncate pb-4'
            ">
          <div class="m-4 ml-8 w-20 h-20 rounded-3xl hidden md:block" :class="accordionOpen.guide
              ? 'bg-blue-700 duration-500'
              : 'bg-indigo-200 duration-500'
            ">
            <Guide :stroke="accordionOpen.guide ? 'white ' : '#1B26E2'" class="m-6 hidden md:block" />
          </div>

          <div class="max-w-full grid w-full">
            <h2 class="my-4 mx-6 text-2xl">Průvodce</h2>
            <p class="mx-6 mb-2" :class="accordionOpen.guide ? 'leading-8 pb-4' : 'truncate'">
              Zde se dozvíte vše potřebné o procesu prodeje nemovitosti.
              Přehledná a srozumitelná charakteristika jednotlivých fází je
              doplněna šablonami pro popis nemovitosti do inzerce, které působí
              profesionálně a odradí potenciální realitní makléře.
            </p>
          </div>

          <img src="/images/chevron-down 5.png" class="h-fit m-8 absolute right-0 -top-3" :class="accordionOpen.guide
              ? 'transform rotate-180 duration-300'
              : 'transform duration-300'
            " />
        </div>

        <div v-on:click="accordionOpen.strategy = !accordionOpen.strategy"
          class="border-b flex cursor-pointer select-none relative" :class="accordionOpen.strategy
              ? 'border-t border-b border-body-1 bg-body-1 bg-opacity-5 flex pb-2'
              : 'truncate pb-4'
            ">
          <div class="m-4 ml-8 w-20 h-20 rounded-3xl hidden md:block" :class="accordionOpen.strategy
              ? 'bg-blue-700 duration-500'
              : 'bg-indigo-200 duration-500'
            ">
            <Strategy :stroke="accordionOpen.strategy ? 'white ' : '#1B26E2'" class="m-6 hidden md:block" />
          </div>

          <div class="max-w-full grid w-full">
            <h2 class="my-4 mx-6 text-2xl">Strategie</h2>
            <p class="mx-6 mb-2" :class="accordionOpen.strategy ? 'leading-8 pb-4' : 'truncate'">
              V sekci strategie si připravíte veškeré dokumenty potřebné k
              prodeji nemovitosti. Placený nástroj vám navíc může pomoci
              stanovit přiměřenou, avšak vysokou prodejní cenu bytu či domu.
            </p>
          </div>

          <img src="/images/chevron-down 5.png" class="h-fit m-8 absolute right-0 -top-3" :class="accordionOpen.strategy
              ? 'transform rotate-180 duration-300'
              : 'transform duration-300'
            " />
        </div>

        <div v-on:click="accordionOpen.preparation = !accordionOpen.preparation"
          class="border-b flex cursor-pointer select-none relative" :class="accordionOpen.preparation
              ? 'border-t border-b border-body-1 bg-body-1 bg-opacity-5 flex pb-2'
              : 'truncate pb-4'
            ">
          <div class="m-4 ml-8 w-20 h-20 rounded-3xl hidden md:block" :class="accordionOpen.preparation
              ? 'bg-blue-700 duration-500'
              : 'bg-indigo-200 duration-500'
            ">
            <Preparation :stroke="accordionOpen.preparation ? 'white ' : '#1B26E2'" class="m-6 hidden md:block" />
          </div>

          <div class="max-w-full grid w-full">
            <h2 class="my-4 mx-6 text-2xl">Příprava</h2>
            <p class="mx-6 mb-2" :class="accordionOpen.preparation ? 'leading-8 pb-4' : 'truncate'">
              Sekce příprava se zabývá přípravou grafických a audiovizuálních
              podkladů. Najdete zde výčet profesionálů, kteří se věnují designu
              interiérů, fotografování či videu. Můžete si je tak najmout
              například pro tvorbu 3D virtuální prohlídky nebo k tvorbě webových
              stránek.
            </p>
          </div>

          <img src="/images/chevron-down 5.png" class="h-fit m-8 absolute right-0 -top-3" :class="accordionOpen.preparation
              ? 'transform rotate-180 duration-300'
              : 'transform duration-300'
            " />
        </div>

        <div v-on:click="accordionOpen.advertising = !accordionOpen.advertising"
          class="border-b flex cursor-pointer select-none relative" :class="accordionOpen.advertising
              ? 'border-t border-b border-body-1 bg-body-1 bg-opacity-5 flex pb-2'
              : 'truncate pb-4'
            ">
          <div class="m-4 ml-8 w-20 h-20 rounded-3xl hidden md:block" :class="accordionOpen.advertising
              ? 'bg-blue-700 duration-500'
              : 'bg-indigo-200 duration-500'
            ">
            <Advertising :stroke="accordionOpen.advertising ? 'white ' : '#1B26E2'" class="m-6 hidden md:block" />
          </div>

          <div class="max-w-full grid w-full">
            <h2 class="my-4 mx-6 text-2xl">Inzerce</h2>
            <p class="mx-6 mb-2" :class="accordionOpen.advertising ? 'leading-8 pb-4' : 'truncate'">
              Jakmile máte vše připraveno, přichází na řadu inzerce. Jedním
              kliknutím zveřejníte svůj inzerát s prodejem nemovitosti na
              významné realitní servery. Dostáváte se tak do nejdůležitější fáze
              – prodej. Doplňkovými službami jsou zde prodej nemovitosti
              investorovi (investor však musí ještě před započetím inzerce
              nemovitost nacenit) a sjednání profesionála, který za vás
              zrealizuje prohlídky se zájemci o koupi.
            </p>
          </div>

          <img src="/images/chevron-down 5.png" class="h-fit m-8 absolute right-0 -top-3" :class="accordionOpen.advertising
              ? 'transform rotate-180 duration-300'
              : 'transform duration-300'
            " />
        </div>

        <div v-on:click="accordionOpen.docs = !accordionOpen.docs"
          class="border-b flex cursor-pointer select-none relative" :class="accordionOpen.docs
              ? 'border-t border-b border-body-1 bg-body-1 bg-opacity-5 flex pb-2'
              : 'truncate pb-4'
            ">
          <div class="m-4 ml-8 w-20 h-20 rounded-3xl hidden md:block" :class="accordionOpen.docs
              ? 'bg-blue-700 duration-500'
              : 'bg-indigo-200 duration-500'
            ">
            <Docs :stroke="accordionOpen.docs ? 'white ' : '#1B26E2'" class="m-6 hidden md:block" />
          </div>

          <div class="max-w-full grid w-full">
            <h2 class="my-4 mx-6 text-2xl">Právní dokumentace</h2>
            <p class="mx-6 mb-2" :class="accordionOpen.docs ? 'leading-8 pb-4' : 'truncate'">
              S aplikací MakléřeNechci máte k dispozici všechny potřebné právní
              dokumenty. Pořídíte zde základní smlouvy na rezervaci i prodej
              nemovitosti. V případě potřeby si pak můžete domluvit schůzku s
              prověřenou advokátní kanceláří.
            </p>
          </div>

          <img src="/images/chevron-down 5.png" class="h-fit m-8 absolute right-0 -top-3" :class="accordionOpen.docs
              ? 'transform rotate-180 duration-300'
              : 'transform duration-300'
            " />
        </div>
      </div>

      <div class="text-center">
        <button class="h-16 px-8 text-white font-bold rounded-md bg-body-3 my-16 hover:opacity-70 duration-200"
          @click="$router.push('/registrace')">
          Registrovat se do aplikace
        </button>
      </div>
    </section>

    <section>
      <div class="bg-body-1 rounded-br-4xl">
        <div class="grid grid-cols-1 md:grid-cols-2 justify-items-center max-w-screen-xl mx-auto pt-0 md:pt-16 pb-16">
          <div class="max-w-xmd my-12 mx-6">
            <h2 class="text-white font-medium text-4xl leading-10 mx-4 md:mx-0 mb-8">
              Ušetříte desítky i stovky tisíc za provizi obchodníkům s
              nemovitostmi
            </h2>
            <img src="/images/Union.png" class="block md:hidden px-5" />
            <p class="text-white leading-8 tracking-wide text-lg mt-8 md:mt-12 mx-4 md:mx-0 font-semibold">
              Prodej nemovitosti s využitím služeb realitního makléře s sebou
              přináší jednu obrovskou výhodu – úsporu času. V případě, že nemáte
              prostor na zajištění prohlídek a související administrativu pro
              vás tak pravděpodobně bude lepší právě tato cesta. Pokud však máte
              čas a chuť nemovitost prodat svépomocí, je aplikace MakléřeNechci
              ten nejlepší dostupný nástroj. Nikdo nepozná, že se prodeji
              nevěnujete profesionálně. Nemovitost prodáte rychle, za výhodnou
              cenu a bez zbytečných nákladů na realitku.
            </p>
          </div>
          <div class="max-w-xmd my-12 mx-6 hidden md:block">
            <img src="/images/Union.png" />
          </div>
        </div>
      </div>
    </section>
    <registration-form></registration-form>
  </main>
</template>

<script>
import RegistrationForm from "@/components/RegistrationForm.vue";
import Advertising from "@/components/accordion/Advertising.vue";
import Preparation from "@/components/accordion/Preparation.vue";
import Docs from "@/components/accordion/Docs.vue";
import Strategy from "@/components/accordion/Strategy.vue";
import Guide from "@/components/accordion/Guide.vue";

export default {
  name: "OldLandingPage",
  components: {
    RegistrationForm,
    Advertising,
    Preparation,
    Docs,
    Strategy,
    Guide,
  },
  data() {
    return {
      propertyTypes: "",
      userProfile: {},
      accordionOpen: {
        guide: false,
        strategy: false,
        preparation: false,
        advertising: false,
        docs: false,
      },
    };
  },
  methods: {
    accordionHandler() {
      this.accordionOpen = !this.accordionOpen;
    },
  },
};
</script>
