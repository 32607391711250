<template>
  <section>
    <div class="mt-16">
      <h2 class="text-center text-body-4 text-4xl mb-4 select-none">
        {{ newPropertyId ? 'Dokončení registrace' : 'Registrace' }}
      </h2>
      <p
        class="text-center max-w-form p-4 mx-auto leading-7 text-body-4 select-none font-semibold"
      >
        Registrací zdarma získáte přístup do aplikace MakléřeNechci.
      </p>
    </div>

    <div class="max-w-form mt-8 mx-auto mb-3 px-4">
      <label class="text-gray-600 font-bold mb-3">Jméno a příjmení*</label>
      <div class="flex items-center mt-2 mb-2">
        <input
          type="text"
          class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
          v-model="userProfile.name"
          :class="
            submitted && !$v.userProfile.name.required ? 'border-red-500' : ''
          "
        />
      </div>
      <div
        v-if="submitted && !$v.userProfile.name.required"
        class="font-medium text-sm text-red-500"
      >
        {{ $t("ErrorMessages.FieldIsRequired") }}
      </div>
    </div>

    <div class="max-w-form mt-8 mx-auto mb-3 px-4">
      <div class="grid grid-cols-2 w-full">
        
        
      </div>
      <div class="flex gap-5 mt-2 mb-2">
        <div class="flex flex-col">
          <label class="text-gray-600 font-bold">Předvolba</label>
          <input
            style="width: 80px"
            v-model="userProfile.code"
            @input="plusHandler()"
            class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
          />
        </div>
        <div class="flex flex-col w-full">
          <label class="text-gray-600 font-bold">Telefon*</label>
          <input
            type="tel"
            name="tel"
            autocomplete="off"
            class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
            v-model="userProfile.phone"
            v-mask="priceMask"
            :class="
              submitted && !$v.userProfile.phone.required
                ? 'border-red-500'
                : ''
            "
          />
        </div>
      </div>
      <div class="grid grid-cols-2 w-full">
        <div></div>
        <div
          v-if="submitted && !$v.userProfile.phone.required"
          class="font-medium text-sm text-red-500"
        >
          {{ $t("ErrorMessages.FieldIsRequired") }}
        </div>
      </div>
    </div>

    <div class="max-w-form mt-8 mx-auto mb-3 px-4">
      <label class="text-gray-600 font-bold mb-3">Email*</label>
      <div class="flex items-center mt-2 mb-2">
        <input
          type="email"
          name="email"
          class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
          v-model="userProfile.email"
          @change="validateEmail()"
          :class="
            (submitted || emailChecked) &&
            (!$v.userProfile.email.required ||
              !$v.userProfile.email.email ||
              emailInDatabase)
              ? 'border-red-500'
              : ''
          "
        />
      </div>
      <div
        v-if="(submitted || emailChecked) && !$v.userProfile.email.email"
        class="font-medium text-sm text-red-500"
      >
        {{ $t("ErrorMessages.EmailBadFormat") }}
      </div>
      <div
        v-if="(submitted || emailChecked) && !$v.userProfile.email.required"
        class="font-medium text-sm text-red-500"
      >
        {{ $t("ErrorMessages.FieldIsRequired") }}
      </div>
      <div v-if="emailInDatabase" class="font-medium text-sm text-red-500">
        {{ $t("ErrorMessages.EmailAlreadyExist") }}
        <p>
          <button class="text-body-1" @click="login()">Přihlásit?</button>
        </p>
      </div>
    </div>

    <div class="max-w-form mt-8 mx-auto mb-3 px-4">
      <label class="text-gray-600 font-bold">Heslo*</label>
      <div class="flex items-center mt-2 mb-2">
        <input
          type="password"
          class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
          v-model="userProfile.password"
          :class="
            submitted && !$v.userProfile.password.required
              ? 'border-red-500'
              : ''
          "
        />
      </div>
      <div
        v-if="submitted && !$v.userProfile.password.required"
        class="font-medium text-sm text-red-500"
      >
        {{ $t("ErrorMessages.FieldIsRequired") }}
      </div>
      <div
        v-if="submitted && !$v.userProfile.password.minLength"
        class="font-medium text-sm text-red-500"
      >
        {{ $t("ErrorMessages.PasswordMinLength") }}
      </div>
    </div>

    <div class="max-w-form mt-8 mx-auto mb-3 px-4">
      <label class="text-gray-600 font-bold">Zopakovat heslo*</label>
      <div class="flex items-center mt-2 mb-2">
        <input
          type="password"
          name="password"
          class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
          v-model="userProfile.passwordAgain"
          :class="
            (submitted && !$v.userProfile.passwordAgain.required) ||
            (submitted && !$v.userProfile.passwordAgain.sameAsPassword)
              ? 'border-red-500'
              : ''
          "
        />
      </div>
      <div
        v-if="submitted && !$v.userProfile.passwordAgain.required"
        class="font-medium text-sm text-red-500"
      >
        {{ $t("ErrorMessages.FieldIsRequired") }}
      </div>

      <div
        v-if="submitted && !$v.userProfile.passwordAgain.sameAsPassword"
        class="font-medium text-sm text-red-500"
      >
        {{ $t("ErrorMessages.PasswordsAreNotSame") }}
      </div>
    </div>

    <div class="max-w-form mt-8 mx-auto mb-3 px-4">
      <label class="flex items-center">
        <span
          :style="
            submitted && userProfile.agree === false
              ? 'height: 34px; background: red; paddingTop: 1px; paddingLeft: 1px; paddingRight: 1px; borderRadius: 4px; '
              : ''
          "
        >
          <input
            type="checkbox"
            class="form-checkbox h-8 w-8"
            v-model="userProfile.agree"
          />
        </span>

        <span class="ml-4 mb-1.5 text-xl"
          >Souhlasím se zpracováním osobních údajů.</span
        >
      </label>
      <div
        v-if="submitted && userProfile.agree === false"
        class="font-medium text-sm text-red-500"
      >
        {{ $t("ErrorMessages.FieldIsRequired") }}
      </div>
    </div>

    <div class="text-center my-10">
      <button
        class="focus:outline-none px-8 font-bold text-white h-16 bg-body-3 hover:opacity-70 duration-200 rounded-md"
        @click="register()"
      >
        Zaregistrovat se
      </button>
    </div>
  </section>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from "axios";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { getUserEmail } from "../services/rest/services";

const currencyPriceMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 12,
});

export default {
  data() {
    return {
      codeCantIncludes:
        "abcdefghcijklmnopqrstuvwxyzABCDEFGHCIJKLMNOPQRSTUVWXYZ",
      userProfile: {
        agree: false,
        code: "+420",
      },
      emailChecked: false,
      submitted: false,
      userJwt: "",
      emailInDatabase: false,
      priceMask: currencyPriceMask,
      newPropertyId: new URLSearchParams(window.location.search).get('id')
    };
  },
  methods: {
    async register() {
      this.emailInDatabase = false;
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || this.userProfile.agree === false) {
        return window.localStorage.setItem("registrace", false);
      } else {
        try {
          const emails = await getUserEmail(this.userProfile.email);
          if (emails.email) {
            window.localStorage.setItem("registrace", false);
            return (this.emailInDatabase = true);
          } else if (!emails.email) {
            if (this.userProfile.code === "+") {
              this.userProfile.code = "";
            }
            // cant includes letters!
            const letterArray = this.codeCantIncludes.split("");
            letterArray.forEach((letter) => {
              if (this.userProfile.code.includes(letter)) {
                this.userProfile.code = "";
              }
            });
            const registeredUser = await axios.post(
              `${process.env.VUE_APP_STRAPI_API_URL}/auth/local/register`,
              {
                username: this.userProfile.email,
                email: this.userProfile.email,
                nameSurname: this.userProfile.name,
                password: this.userProfile.password,
                phone: this.userProfile.code + this.userProfile.phone,
              }
            );
            if (registeredUser.status === 200 && this.newPropertyId) {
              await axios.post(
              `${process.env.VUE_APP_STRAPI_API_URL}/properties/connectPropertyWithUser`,
              {
                userId: registeredUser.data.user.id,
                propertyId: this.newPropertyId,
              }
            );
            }
            Swal.fire(this.$t("SuccessMessages.SuccessfullyRegistered"));
          }
          this.$router.push("/potvrzeni-registrace-emailem");
        } catch (e) {
          return e;
        }
      }
    },
    plusHandler() {
      if (this.userProfile.code.length === 0) {
        this.userProfile.code = "+";
      }
      if (this.userProfile.code.length > 5) {
        this.userProfile.code = "+";
      }
    },
    validateEmail() {
      this.emailChecked = true;
      this.$v.$touch();
    },
    login() {
      window.localStorage.clear();
      this.$router.push("prihlaseni");
    },
  },
  validations: {
    userProfile: {
      name: { required },
      phone: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      passwordAgain: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("password"),
      },
      agree: { required },
    },
  },
};
</script>
