<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-on="$listeners"
  >
    <path
      d="M5.333 26a3.333 3.333 0 0 1 3.333-3.333h18"
      stroke="current"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M8.666 2.667h18v26.666h-18A3.333 3.333 0 0 1 5.333 26V6a3.333 3.333 0 0 1 3.333-3.333v0z"
      stroke="current"
      stroke-width="2.667"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Guide",
};
</script>
