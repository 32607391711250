<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-on="$listeners"
  >
    <path
      d="M30.666 25.333A2.667 2.667 0 0 1 28 28H4a2.667 2.667 0 0 1-2.667-2.667V10.667A2.667 2.667 0 0 1 4 8h5.333L12 4h8l2.666 4H28a2.667 2.667 0 0 1 2.666 2.667v14.666z"
      stroke="current"
      stroke-width="2.667"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M16 22.667A5.333 5.333 0 1 0 16 12a5.333 5.333 0 0 0 0 10.667z"
      stroke="current"
      stroke-width="2.667"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Preparation",
};
</script>
